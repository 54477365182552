@import 'assets/scss/mixins';
@import 'assets/scss/variables';

//Header
.modal-header {
    background: $modal-header-bg-color;
    display: flex;
    min-height: rem(60px);
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $modal-header-border-color;

    .modal--header-icon-button {
        color: $modal-header-close-btn-color;
        flex-shrink: 0;
        display: flex;
        align-self: start;
        padding: 0;
        height: rem(60px);
        width: rem(60px);
        align-items: center;
        justify-content: center;

        img {
            width: rem(20px);
            height: auto;
            padding: 0;
        }

        &:hover,
        &:focus {
            background-color: $modal-header-close-btn-hover-bg-color;

            img {
                background: none;
            }
        }

        &:focus {
            img {
                border-radius: unset;
                outline: none;
            }
        }
    }

    .modal-headline {
        padding: rem(14px) rem(12px) rem(14px) rem(20px);
        margin: 0;
        color: $modal-header-font-color;
    }

    .modal-alt & {
        background-color: $modal-alt-header-bg-color;
        border-bottom: 1px solid $modal-alt-header-border-color;

        .modal-headline {
            color: $modal-alt-header-font-color;
        }

        .modal--header-icon-button {

            &:hover,
            &:focus {
                background-color: $modal-alt-header-close-btn-hover-bg-color;
            }

            svg {
                color: $modal-alt-header-close-btn-color;
            }
        }
    }
}
@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.document-list-modal {
    &__grid-container {
        display: grid;
        grid-template-columns: auto auto auto;
        font-size: rem(12px);
    }

    &__column-header {
        font-weight: bold;
        padding: 1rem;
        border-bottom: rem(1px) solid $grey-250;
    }

    &__row {
        border-bottom: rem(1px) solid $grey-250;
        padding: 1rem;
        font-size: rem(11px);
        cursor: pointer;

        &:hover {
            background-color: $grey-100;
        }
    }

    &__version {
        background-color: $black;
        padding: rem(3px) rem(6px);
        border-radius: rem(16px);
        color: $white;
        font-size: rem(10px);
    }

    &__status {
        &::before {
            display: inline-block;
            width: rem(7px);
            height: rem(7px);
            border-radius: 50%;
            margin-right: 0.3rem;
            content: "";
        }

        &--active {
            &::before {
                background-color: $green-400;
            }
        }

        &--inactive {
            &::before {
                background-color: $grey-400;
            }
        }

    }


    &__column-header {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: $grey-100;
        }

        &__sort {
            display: flex;
            flex-direction: column;

            img {
                &:first-child {
                    margin-bottom: rem(-9px);
                }

                &:last-child {
                    margin-top: rem(-9px);
                }
            }
        }
    }
}
@import 'assets/scss/variables';
@import "/src/assets/scss/mixins";

//Body
.modal-body {
    padding: rem(20px);
    display: block;
    overflow: auto;
    flex: 1;
    color: $black;
    font-size: 0.875rem;
}
@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.search {
  &__container {
    section {
      background-color: $serach-bg;
      padding: .75rem;
      @include flex(row, space-between, center);
      gap: .5rem;
      margin-bottom: .75rem;
      border: .06rem solid darken($grey-200, 2%);
      border-radius: .5rem;
      min-width: 18rem;

      img {
        @include square(1rem);
        cursor: pointer;
        opacity: .5;

        &.close {
          display: none;
          @include square(.8rem);
        }
      }

      input {
        width: 100%;
        color: $black;
        background-color: transparent;
        border: none;
        outline: none;

        &::placeholder {
          font-size: .7rem;
          color: lighten($grey-450, 25%);
        }

        &:focus+.close,
        &:not(:placeholder-shown)+.close {
          display: block;
        }
      }
    }

    input:disabled {
      cursor: not-allowed;
    }
  }
}
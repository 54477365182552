@import 'assets/scss/mixins';
@import 'assets/scss/variables';
@import 'assets/scss/typography';

$animationLength: 1.5s;
$animationRadius: 12px;
$dotSize: 8px;
.dot-loader-wrapper {
  //background: rgba(26, 26, 36, .85);
  background-color:rgba(138, 138, 138, 0.5);
  @include square(100%);
  position: absolute;
  z-index: 9999;
  &__container {
    @include centre;
    .tbl-loader {
      position: relative;
      width: $animationRadius * 3 + $dotSize;
      height: $dotSize;
      margin: $animationRadius auto;
      top: 45%;
      h4{
        color: $grey-450;
        left: -1rem;
        position: relative;
        top: 3rem;
        font-weight: 300;
      }
      .dot {
        display: inline-block;
        width: $dotSize;
        height: $dotSize;
        border-radius: $dotSize * 0.5;
        background: $grey-550;
        position: absolute;
        &.dot_1 {
          animation: animateDot1 $animationLength linear infinite;
          left: $animationRadius;
          background: $yellow-400;
        }
        &.dot_2 {
          animation: animateDot2 $animationLength linear infinite;
          animation-delay: $animationLength / 3;
          left: $animationRadius * 2;
        }

        &.dot_3 {
          animation: animateDot3 $animationLength linear infinite;
          left: $animationRadius;
        }

        &.dot_4 {
          animation: animateDot4 $animationLength linear infinite;
          animation-delay: $animationLength / 3;
          left: $animationRadius * 2;
        }
      }
    }
    @keyframes animateDot1 {
      0% {
        transform: rotate(0deg) translateX(-$animationRadius);
      }
      25% {
        transform: rotate(180deg) translateX(-$animationRadius);
      }
      75% {
        transform: rotate(180deg) translateX(-$animationRadius);
      }
      100% {
        transform: rotate(360deg) translateX(-$animationRadius);
      }
    }

    @keyframes animateDot2 {
      0% {
        transform: rotate(-0deg) translateX(-$animationRadius);
      }
      25% {
        transform: rotate(-180deg) translateX(-$animationRadius);
      }
      75% {
        transform: rotate(-180deg) translateX(-$animationRadius);
      }
      100% {
        transform: rotate(-360deg) translateX(-$animationRadius);
      }
    }

    @keyframes animateDot3 {
      0% {
        transform: rotate(0deg) translateX($animationRadius);
      }
      25% {
        transform: rotate(180deg) translateX($animationRadius);
      }
      75% {
        transform: rotate(180deg) translateX($animationRadius);
      }
      100% {
        transform: rotate(360deg) translateX($animationRadius);
      }
    }

    @keyframes animateDot4 {
      0% {
        transform: rotate(-0deg) translateX($animationRadius);
      }
      25% {
        transform: rotate(-180deg) translateX($animationRadius);
      }
      75% {
        transform: rotate(-180deg) translateX($animationRadius);
      }
      100% {
        transform: rotate(-360deg) translateX($animationRadius);
      }
    }
  }
  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
}
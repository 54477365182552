@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";

.chat-history-panel {
    width: 21vw;
    height: calc(100vh - 5.5rem);
    background-color: $grey-250;
    animation: message .3s ease;
    @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);

    &__container {
        padding: 1rem;
        @include flex(column, flex-start, flex-start);
        gap: 2rem;
        width: 100%;
        height: calc(100vh - 6rem);

        .panel-header {
            @include flex(column, flex-start, flex-start);
            gap: 1rem;
            font-weight: bold;
            width: 100%;

            &__date-picker {

                @include for-desktop {
                    width: 100%;
                }

                @include for-small-screen {
                    width: auto;
                }

                .react-daterange-picker {
                    &__calendar {
                        width: 18.75rem;
                    }

                    &__clear-button,
                    &__calendar-button {
                        padding: 4px;

                        svg {
                            width: 1rem;
                            height: 1rem;
                        }
                    }

                    // &__wrapper {
                    //     width: 100%;
                    // }

                    &__inputGroup {
                        font-size: 12px;
                    }
                }

            }

            &__clear-history {
                align-self: flex-end;

                button {
                    background-color: $white;
                    border: 1px solid $black;
                    padding: 0.5rem;
                    @include flex(row, flex-start, center);
                    gap: 0.5rem;
                    font-weight: bold;
                    font-family: $ey-font-light;
                }
            }

            &--bold {
                font-weight: bold;
            }
        }

        .tabs {
            position: relative;
            width: 100%;
            @include flex(row, flex-start, center);

            span {
                position: relative;
                cursor: pointer;
                color: $black;
                transition: color 0.3s;
                padding: 0.5rem 0.75rem;
                flex: 1;
                text-align: center;

                &:after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: block;
                    width: 0;
                    @include size(0, 0.15rem);
                    background-color: $black;
                    margin: auto;
                    content: "";
                    transition: width 0.3s;
                }

                &.active {
                    font-weight: bold;

                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        .list-wrapper {
            @include flex(column, flex-start, flex-start);
            gap: 1rem;
            height: calc(100vh - 7rem);
            overflow-y: auto;
            padding-right: 0.5rem;
            width: 100%;
            @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);

            &__group {
                @include flex(column, flex-start, flex-start);
                gap: 0.2rem;
                width: 100%;

                .group-name {
                    font-weight: bold;
                }

                .list-wrapper-item {
                    @include flex(row, space-between, center);
                    gap: 0.5rem;
                    background-color: $white;
                    color: black;
                    padding: 1rem;
                    width: 100%;
                    cursor: pointer;

                    .item-bot {
                        flex: 0.2;
                    }

                    .item-text {
                        flex: 3;
                        color: $grey-500;
                        @include ellipsis;
                        max-width: 17.25rem;
                    }

                    .item-delect {
                        flex: 0.12;
                    }
                }
            }
        }

        .no-data {
            color: $grey-500;
            @include flex (column, center, center);
            width: 100%;
            height: calc(100vh - 16rem);
        }
    }
}

@keyframes message {
    from {
        transform: translateX(-10rem);
    }

    to {
        transform: translateX(0);
    }
}
$shadow-color: #c4c4cd59;
$bg-color: #f0f0f0;
$app-bg : #000;
$black :#1A1A24;
$white : #fff;
$yellow-400 :#FFE600;

$grey-50: #FAFAFC;
$grey-100: #F6F6FA;
$grey-200: #EAEAF2;
$grey-225: #E7E7EA;
$grey-250: #E1E1E6;
$grey-300: #C4C4CD;
$grey-400: #747480;
$grey-450: #3A3A4A;
$grey-480: #585860;
$grey-500: #2E2E38;
$grey-550: #2E2E3C;
$grey-580: #282836;
$grey-600: #23232F;

$green-100: #8CE8AD;
$green-200: #57E188;
$green-300: #34C768;
$green-400: #2DB757;
$green-500: #189D3E;
$green-600: #168736;
$green-700: #13652A;

$blue-100: #87D3F2;
$blue-200: #4EBEEB;
$blue-300: #35A4E8;
$blue-400: #188CE5;
$blue-450 : #2f53f0;
$blue-500: #1777CF;
$blue-600: #155CB4;
$blue-700: #082C65;

$red-100: #FF9A91;
$red-200: #FF736A;
$red-300: #F95D54;
$red-400: #FF4136;
$red-500: #E0362C;
$red-600: #B9251C;
$red-700: #7A130D;

$orange-100: #FFB46A;
$orange-200: #FF9831;
$orange-300: #FF810A;
$orange-400: #FF6D00;
$orange-500: #F76900;
$orange-600: #EB4F00;
$orange-700: #BC2F00;
$orange-warning: #ED6500;


$bot-bg : #1B1B1B;
$bot-color : #BABABA;
$bubble-grey :#D9D9D9;
$serach-bg: #f6f6fa;
$disabled : #d4d4d4;

$colors :#42A5F5 #2196F3 #1E88E5 #1976D2 #1565C0;

$error: $red-300;
$rhythm: 8px !default;
$body-color: $white;
$primary-font: 'ey_interstate';
$disabled-text: $grey-400; // Font Color for disabled elements.
$branding: $yellow-400; // Primary Branding color. Used for accents.

// Images
$image-base: "../../assets/images/";
$background-image-new : $image-base+"bg_new.png";
$background-image : $image-base+"bg.png";

$ease-in-out: 300ms ease-in-out; // Default transition
$ease-in-out-fast: 150ms ease-in-out; // Fast transtition
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1); // Exponential transition

$weight-bold: bold; // Bold font weight.
$weight-regular: normal; // Regular font weight.
$weight-light: lighter; // Thin font weight.


$like : '../../assets/images/like.png';
$dislike : '../../assets/images/dont-like.png';

$modal-bg-color: $white; // Modal background color.
$modal-overlay-bg-color: rgba(26, 26, 36, 0.85); // Modal overlay background color.
$modal-box-shadox: 0 0 11px 8px rgba(0, 0, 0, 0.38); // Modal box shadow.
$modal-header-bg-color: $white; // Modal header background color.
$modal-header-font-color: $grey-500; // Modal header font color.
$modal-header-border-color: $grey-250; // Modal header border color.
$modal-header-close-btn-color: $grey-500; // Modal header close button color.
$modal-header-close-btn-hover-bg-color: $grey-250; // Modal header close button color while hovered or focused.
$modal-footer-border-color: $grey-250; // Modal footer border color.
$modal-alt-header-bg-color: $grey-500; // Modal header alternate variation background color.
$modal-alt-header-font-color: $white; // Modal header alternate variation font color.
$modal-alt-header-border-color: transparent; // Modal header alternate variation border color.
$modal-alt-header-close-btn-color: $white; // Modal header alternate variation close button color.
$modal-alt-header-close-btn-hover-bg-color: $grey-450; // Modal header alternate variation close button color while hovered or focused.

// Icon Button
$btn-icon-color: $black; // Icon button color.
$btn-icon-hover-color: $black; // Icon button color while hovered.
$btn-icon-hover-bg-color: $grey-250; // Icon button background color while hovered.
$btn-icon-focus-color: $black; // Icon button color while focused.
$btn-icon-focus-bg-color: $grey-250; // Icon button background color while focused.
$btn-icon-disabled-color: $disabled-text; // Icon button color when disabled.


// Buttons
$btn-font-size: 1rem; // Button font size.
$btn-font-weight: $weight-bold; // Buton font weight.
$btn-padding: 0.429rem; // Button padding.
$btn-padding-medium: 0.572rem; // Button for medium size padding.
$btn-padding-large: 0.715rem; // Button for large size padding.

// Content Switcher
$content-switcher-hover-bg-color: $grey-250; // Content switcher background color while hovered.
$content-switcher-hover-font-color: $black; // Content switcher text color while hovered.
$content-switcher-selected-bg-color: $black; // Content switcher background color when selected.
$content-switcher-selected-font-color: $white; // Content switcher text color when selected.
$content-switcher-default-font-color: $grey-500; // Content switcher default font color.
$content-switcher-default-bg-color: $white; // Content switcher background color.
$content-switcher-btn-border-color: $black; // Content switcher button border color.
$content-switcher-disabled-font-color: $grey-400; // Content switcher default font color.
$content-switcher-disabled-bg-color: $grey-200; // Content switcher default background color.

// Primary Button
$btn-primary-bg-color: $branding; // Primary button background color.
$btn-primary-border-color: $grey-450; // Primary button border color.
$btn-primary-color: $grey-500; // Primary button font color.
$btn-primary-hover-bg-color: $grey-500; // Primary button background color while hovered.
$btn-primary-hover-border-color: $grey-500; // Primary button border color while hovered.
$btn-primary-hover-color: $white; // Primary button font color while hovered.
$btn-primary-disabled-bg-color: $disabled; // Primary button background color when disabled
$btn-primary-disabled-border-color: $disabled; // Primary button border color when disabled
$btn-primary-disabled-color: $disabled-text; // Primary button font color when disabled

// Primary Button (alternative option)
$btn-primary-alt-bg-color: $grey-500; // Primary button with alternative background color.
$btn-primary-alt-border-color: $grey-500; // Primary button with alternative border color.
$btn-primary-alt-color: $white; // Primary button with alternative font color.
$btn-primary-alt-hover-bg-color: $white; // Primary button with alternative background color while hovered.
$btn-primary-alt-hover-border-color: $grey-500; // Primary button with alternative border color while hovered.
$btn-primary-alt-hover-color: $grey-500; // Primary button with alternative font color while hovered.
$btn-primary-alt-disabled-bg-color: $disabled; // Primary button background color when disabled
$btn-primary-alt-disabled-border-color: $disabled; // Primary button border color when disabled
$btn-primary-alt-disabled-color: $disabled-text; // Primary button font color when disabled

// Secondary Button
$btn-secondary-bg-color: $white; // Secondary button background color.
$btn-secondary-border-color: $grey-500; // Secondary button border color.
$btn-secondary-color: $grey-500; // Secondary button font color.
$btn-secondary-hover-bg-color: $grey-500; // Secondary button background color while hovered.
$btn-secondary-hover-border-color: $grey-500; // Secondary button border color while hovered.
$btn-secondary-hover-color: $white; // Secondary button font color while hovered.
$btn-secondary-focus-bg-color: $grey-500; // Secondary button background color while focused.
$btn-secondary-focus-border-color: $grey-500; // Secondary button border color while focused.
$btn-secondary-focus-color: $white; // Secondary button font color while focused.
$btn-secondary-disabled-bg-color: $disabled; // Secondary button background color when disabled.
$btn-secondary-disabled-border-color: $disabled; // Secondary button border color when disabled.
$btn-secondary-disabled-color: $disabled-text; // Secondary button font color when disabled.

// Warning Button
$btn-warn-bg-color: $error; // Warning button background color.
$btn-warn-border-color: $error; // Warning button border color.
$btn-warn-color: $white; // Warning button font color.
$btn-warn-hover-bg-color: $grey-500; // Warning button background color while hovered.
$btn-warn-hover-border-color: $grey-500; // Warning button border color while hovered.
$btn-warn-hover-color: $white; // Warning button font color while hovered.
$btn-warn-focus-bg-color: $grey-500; // Warning button background color while focused.
$btn-warn-focus-border-color: $grey-500; // Warning button border color while focused.
$btn-warn-focus-color: $white; // Warning button font color while focused.
$btn-warn-disabled-bg-color: $disabled; // Warning button background color when disabled.
$btn-warn-disabled-border-color: $disabled; // Warning button border color when disabled.
$btn-warn-disabled-color: $disabled-text; // Warning button font color when disabled.

// Text Button
$btn-text-color: $body-color; // Text link font color.
$btn-text-color-disabled: $disabled-text; // Text link font color for disabled state.
$btn-text-alt-color: $blue-600; // Alternative text link font color.
$btn-text-font-size: 1rem; // Text link font size.
@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";


.feedback-modal-wrapper{
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  @include square(100%);
  background-color:rgba(138, 138, 138, 0.3);
}
.feedback-modal-container{
  position: fixed;
  top: 30%;
  left: 30%;
  z-index: 1000;
  width: 35vw;
  height: auto;
  @include flex(column,space-between,flex-start);
  background-color: $white;
  transform: translate(0%,0%);
  transition: transform 500ms ease-in-out;
  &__header{
      padding: 1rem;
      font-size:1.2rem;
      @include flex(row,space-between,center);
      flex:0.5;
      width:100%;
      border-bottom:0.01rem solid $serach-bg;
      img{
          @include square(1.2rem);
      }
      .close_btn{
          cursor: pointer;
      }
  }
  &__content{
      width: 100%;
      @include flex(column,flex-start,flex-start);
      flex:2;
      gap:1rem;
      padding: 1rem 2rem 1rem 1rem;
      .feedback-text{
        width:100%;
      }
      .rating{
         svg{
          cursor: pointer;
         }
        .star path{
          fill:$black !important;
        }
        .non-starred path{
          fill:none !important;
          stroke: $black;
        }
      }
      .error {
        color: red;
        font-size: 0.8rem;
        margin-top: 0.5rem;
      }
  }
  &__footer{
    @include flex(row,flex-start,flex-start);
    flex:0.5;
    padding: 1rem;
    background: $white;
    align-self: flex-end;
    width: 100%;
    gap:1rem;
    border-top:0.01rem solid $serach-bg;
    button{
      background: $white;
      border: 1px solid $grey-500;
      color: $grey-500;
      padding: 0.6rem 1.5rem;
      font-weight: 700;
      &.primary{
        background: $grey-500;
        border-color:$grey-500;
        color: $white;
      }
      &:disabled{
        background-color: $grey-300;
        border-color: $grey-300;
        color: $grey-400;
        pointer-events: none;
      }
    }
  }
}
.feedback-wrapper{
    position: absolute;
    right: -3rem;
    top: 50%;
    transform: rotate(270deg);
    button{
      background-color: $black;
      color: $white;
      @include flex(row,flex-start,center);
      font-family: inherit;
      gap: 0.5rem;
      padding: 0.5rem;
      cursor: pointer;
      span{
        font-weight:bold;
      }
      img{
        transform: rotate(90deg);
      }
    }
  }
@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-custom-chat-container {
  @include flex(row, center, flex-start);
  overflow: hidden;

  &__chat-wrapper {
    width: 80vw;
    position: absolute;
    bottom: 2rem;
    overflow: hidden;
    transition: width .3s ease-in-out;

    .gpo-multiple-wrapper {
      @include grid(40% 1fr, flex-end, 2rem);

      .gpo-chat-send {
        display: none;
      }

      .gpo-custom-chat-messages {
        min-height: calc(100vh - 7rem);
        display: flex;
        align-items: flex-end;
        margin: 0;
      }

      .gpo-tooltip {
        left: -12.5rem;

        &:after {
          right: -12rem;
          left: auto;
        }
      }

      .gpo-chatbot-container__banner {
        display: none;
      }

      .gpo-chat-wrapper {
        max-height: calc(100vh - 8rem);
        overflow-y: auto;
        @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);

        &__list {
          &:first-child {
            .chats {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  &__feedback-wrapper {
    position: absolute;
    right: 2rem;
    bottom: 2rem;

    img {
      @include square(3rem);
    }
  }

  &.message-list,
  &.chat-history-list {
    justify-content: flex-start;
    gap: 2rem;
    align-items: flex-end;

    .gpo-custom-chat-container__chat-wrapper {
      position: relative;
      width: 100%;
      bottom: 2rem;
      margin-top: 1.5rem;
      margin-right: 1.5rem;
    }
  }
}

.gpo-pdf-viewer {
  // width: 650px;
  position: relative;

  .react-pdf__Document {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 10rem);
    @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
  }

  canvas {
    width: 100% !important;
  }
}

.fullscreen-modal {
  position: fixed;
  left: 0;
  top: 0;
  @include square(100%);
  background-color: $white;

  header {
    @include flex(row, space-between, center);
    background-color: $grey-600;
    padding: 1rem;
    color: $white;

    img {
      cursor: pointer;
    }
  }

  &__body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 3rem);
    @include scrollbars(0.6rem, darken($grey-600, 5%), $grey-450);

    .rpv-core__inner-pages {
      @include scrollbars(0.6rem, darken($grey-600, 5%), $grey-450);
    }
  }
}
@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.displayText {

    // transition: border-color 1s;
    // border : 0.1rem solid $grey-300;
    span.highlight {
        display: inline-block;
        color: $black;
        font-weight: 600;
        padding: 0.2rem;
        background-color: #188CE5;
    }

    &.highlighted {
        //border : 0.1rem solid $yellow-400;
    }
}
@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.messages {
    width: 21vw;
    height: calc(100vh - 5.5rem);
    background-color: $white;
    animation: message .3s ease;
    @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
    overflow-y: auto;

    &__container {
        padding: 1rem;

        header {
            @include flex(row, space-between, center);
            margin-bottom: 1.25rem;

            img {
                cursor: pointer;
            }
        }

        .list-wrapper {
            .list {
                @include flex(row, flex-start, center);
                cursor: pointer;
                padding: .3rem 0;

                img {
                    @include square(1.75rem);
                    margin-right: .5rem;
                }

                .details {
                    @include flex(column, center, flex-start);
                    width: 88%;

                    .top {
                        @include flex(row, space-between, center);
                        width: 100%;
                        margin-bottom: .2rem;

                        h6 {
                            @include ellipsis;
                            max-width: 60%;
                        }

                        span {
                            font-size: .65rem;
                            color: $grey-500;
                            opacity: .5;
                        }
                    }

                    p {
                        font-size: .62rem;
                        color: $grey-500;
                        opacity: .5;
                        @include ellipsis;
                        max-width: 85%;
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                &.active {
                    position: relative;

                    &::before {
                        content: "";
                        left: -1rem;
                        background-color: $blue-400;
                        @include size(.25rem, 100%);
                        position: absolute;
                    }

                    &::after {
                        content: "";
                        left: -0.857rem;
                        background-color: $grey-400;
                        width: 112%;
                        height: 100%;
                        position: absolute;
                        opacity: 10%;
                    }
                }
            }

            p.no-data {
                font-weight: bold;
                color: $grey-500;
            }
        }
    }
}

@keyframes message {
    from {
        transform: translateX(-10rem);
    }

    to {
        transform: translateX(0);
    }
}
@import "/src/assets/scss/variables";
.Typewriter {
  width: 100%;
  &__cursor {
    position: relative;
    color: transparent;
    &:after {
      content: "";
      width: 0.3rem;
      height: 1rem;
      background-color: $yellow-400;
      position: absolute;
      left: -.01rem;
      top: .03rem;
    }
  }
}
.react-typewriter-text-wrap{
  width: 100%;
  background-color: $bot-bg;
  border-top-left-radius: 0.3rem !important;
  color: $white;
  max-width: 30rem;
  border-radius: 1rem;
  padding: 0.75rem 1.5rem;
  line-height: 1.2rem;
  font-size: 0.8rem;
   .react-typewriter-text{
    line-height: 1.2rem;
    font-size: 0.8rem;
    font-weight: normal;
   }
}
.text-message{
.content{
  word-break: break-word;
 }
.add-cursor-animate {
  background-color: $yellow-400;
  display: inline;
  padding: 0 1px;
  &.hide{
    display:none;
  }
}
}
p{
  a{
    color:$yellow-400 !important;
  }
}

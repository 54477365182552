@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-tooltip {
  position: absolute;
  left: 2rem;
  width: 20rem;
  cursor: default;
  z-index: 999;

  &__container {
    background-color: $grey-600;
    padding: 0.75rem;
    border-radius: .5rem;
  }

  &__row {
    @include grid(6rem 1fr, center, 0);

    &--source-data {
      align-items: baseline;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:last-child {
      display: block;
    }
  }

  &__title {
    color: $bot-color;
    flex: 0.5;

    font: {
      size: 0.7rem;
    }
  }

  &__status {
    border-radius: 2rem;
    border: 0.09rem solid $grey-200;
    padding: 0 0.5rem;
    display: flex;
    width: fit-content;

    font: {
      size: 0.8rem;
    }

    &--excellent {
      border-color: $green-400;
    }

    &--poor {
      border-color: $red-400;
    }

    &--fair {
      border-color: $orange-200;
    }
  }

  &__view {
    @include flex(row, flex-start, center);
    flex: 1;
    gap: 0.2rem;
    cursor: pointer;

    font: {
      size: 0.85rem;
      weight: 700;
    }

    img {
      width: 0.7rem !important;
      margin-left: 0.3rem;
    }
  }

  &__pages {
    flex: 1;

    font: {
      size: 0.85rem;
    }

    span a {
      color: $blue-300;
      margin-left: 0.5rem;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__document-source {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    p {
      padding: 0 !important;
      background-color: #23232F !important;

      span:last-child {
        padding: rem(3px) rem(6px);
        border-radius: rem(16px);
        color: $black;
        background-color: $yellow-400;
        font-size: 0.69rem;
      }
    }
  }

  &::before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 0;
    border-top: solid 1rem transparent;
    border-bottom: solid 1rem transparent;
    border-right: solid 1rem $grey-600;
    left: -2rem;
    top: 3.5rem;
    position: relative;
  }
}